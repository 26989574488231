.body {
    background-color: white;
}

.w-120 {
    width: 120px;
}

.h-120 {
    height: 120px;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 14px !important;
}

.batch-list-card {
    max-height: 400px;
    overflow-y: auto;
    list-style-type: none;
    padding: 10px;
    z-index: 1;
    &::-webkit-scrollbar {
        display: none;
    }
}

.batch-list {
    .edit-button {
        width: 0px !important;
        padding: 6px 20px 6px 21px !important;
    }
    .btn{
        --cui-btn-padding-y:10px;
    }
}
.red{
    color: red !important;

}
.green{
    color: green !important;
}
.user-list{
    #dropdown-basic{
        background: none !important;
        border: none !important;
    }
}